import React from 'react';
import styles from './Cards.module.css';

const Cards = () => {
  const cardData = [
    {
      title: 'Akvaponické farmy',
      description: 'Ekologické a efektivní systémy pro pěstování rostlin a chov ryb. Od malých farem (10m2) po komerční modulové farmy.',
      icon: '🥬', // Zde můžete nahradit SVG ikonou
      color: '#7ED957',
    },
    {
      title: 'IoT řešení na míru',
      description: 'Automatizace a sběr dat pro zemědělství a další oblasti. Zejména v oboru hydroponie, akvaponie.',
      icon: '☁️',
      color: '#A2D9F7',
    },
    {
      title: 'Moderní technologie',
      description: 'K IoT projektu je přehledná aplikace, se všemi ukazately a grafy přímo ve webové aplikaci.',
      icon: '🛠️',
      color: '#B2D8B2',
    },
    {
      title: 'Zabezpečená data v Cloudu',
      description: 'Vaše data jsou zabezpečená v moderním cloudovém uložišti.',
      icon: '🌱',
      color: '#76C893',
    },
  ];

  return (
    <div className={styles.cardsContainer}>
      {cardData.map((card, index) => (
        <div className={styles.card} key={index} style={{ borderColor: card.color }}>
          <div className={styles.cardIcon} style={{ backgroundColor: card.color }}>
            {card.icon}
          </div>
          <h3 className={styles.cardTitle}>{card.title}</h3>
          <p className={styles.cardDescription}>{card.description}</p>
        </div>
      ))}
    </div>
  );
};

export default Cards;
