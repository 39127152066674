import React from 'react';
import ArticleText from '../Post/PostText';
// import styles from './Services.module.css';
// import StrongText from '../Post/StrongText';
import Cards from '../Card/Cards';
import MyGallery from '../MyGallery/MyGallery';
const Services = () => {
  return (
    <div id="serviceSection">
      <Cards></Cards>
      <ArticleText>Ukázka aquaponické farmy na Plzeňsku</ArticleText>
      <div style={{ maxHeight: '700px', overflow: 'hidden', display: 'flex', justifyContent: 'center' }}>
        <MyGallery></MyGallery>
      </div>
    </div>
  );
};

export default Services;
