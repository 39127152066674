import React from 'react';
import styles from './Hero.module.css';
import Article from '../../../components/Post/Post';
import ArticleText from '../../../components/Post/PostText';
import RawPhotoText from '../../../components/PhotoText/RawPhotoText';
import Services from '../../../components/Services/Services';
import Hydro from '../../../img/Hydro.webp';
import Aqua from '../../../img/Aqua.webp';
import { CiCirclePlus, CiCircleMinus } from 'react-icons/ci';
const Hero = () => {
  return (
    <div className={styles.readContent}>
      <Article title="Hydroponie" img={Hydro} photo="right" alt="hydroponie">
        Hydroponie je inovativní způsob pěstování rostlin bez využití půdy. Rostliny jsou umístěny v substrátu a jejich kořeny vstřebávají nezbytné živiny z živného roztoku, což zabezpečí optimální růst rostliny. Vyzkoušejte hydroponické pěstování zeleniny, ovoce, ale i bylinek a podpořme spolu ekologicky šetrné pěstování.
        <div className={styles.separateCode}>
          <div className="leftSide">
            <div className={styles.itemPlusMinus} style={{ marginTop: '15px' }}>
              <CiCirclePlus className={styles.spaceIcon} color="rgb(117, 192, 68)" size={'20px'} /> Úspora 80 % vody
            </div>
            <div className={styles.itemPlusMinus}>
              <CiCirclePlus className={styles.spaceIcon} color="rgb(117, 192, 68)" size={'20px'} /> Plodiny rostou rychleji
            </div>
            <div className={styles.itemPlusMinus}>
              <CiCirclePlus className={styles.spaceIcon} color="rgb(117, 192, 68)" size={'20px'} /> Větší výnosy plodin
            </div>
            <div className={styles.itemPlusMinus}>
              <CiCirclePlus className={styles.spaceIcon} color="rgb(117, 192, 68)" size={'20px'} /> Vyžaduje menší práci než tradiční zemědělství
            </div>

            <div className={styles.itemPlusMinus}>
              <CiCirclePlus className={styles.spaceIcon} color="rgb(117, 192, 68)" size={'20px'} /> Produkuje potraviny vyšší kvality
            </div>
            <div className={styles.itemPlusMinus}>
              <CiCirclePlus className={styles.spaceIcon} color="rgb(117, 192, 68)" size={'20px'} /> 20-30 % potravin v obchodech je již pěstováno hydroponicky
            </div>
            <div className={styles.itemPlusMinus}>
              <CiCirclePlus className={styles.spaceIcon} color="rgb(117, 192, 68)" size={'20px'} /> Není potřeba půda
            </div>
          </div>
          <div className="rightSide">
            <div style={{ display: 'flex', alignItems: 'center', marginTop: '15px' }}>
              <CiCircleMinus className={styles.spaceIcon} color="red" size={'20px'} /> Potřeba manuálně přidávat živiny
            </div>
          </div>
        </div>
      </Article>
      <Article title="Akvaponie" img={Aqua} photo="right" alt="aquaponie">
        Akvaponie je udržitelný systém pro chov ryb a pěstování rostlin. V aquaponických systémech se využívá odpad z chovu ryb k nutričnímu zásobení rostlin, zatímco rostliny filtrují vodu pro ryby. Tento systém vytváří uzavřený ekosystém, ve kterém se vzájemně podporují ryby a rostliny, a může být použit jako ekologický způsob produkce potravin a čisté vody.
        <div className={styles.separateCode}>
          <div className="leftSide">
            <div style={{ display: 'flex', alignItems: 'center', marginTop: '15px' }}>
              <CiCirclePlus className={styles.spaceIcon} color="rgb(117, 192, 68)" size={'20px'} /> Zdroj živin je přímo od ryb
            </div>
            <div className={styles.itemPlusMinus}>
              <CiCirclePlus className={styles.spaceIcon} color="rgb(117, 192, 68)" size={'20px'} /> Nejvyšší kvalita potravin
            </div>
            <div className={styles.itemPlusMinus}>
              <CiCirclePlus className={styles.spaceIcon} color="rgb(117, 192, 68)" size={'20px'} /> Dvě odvětví příjmů (rostliny, ryby)
            </div>
            <div className={styles.itemPlusMinus}>
              <CiCirclePlus className={styles.spaceIcon} color="rgb(117, 192, 68)" size={'20px'} /> Vysoká úspora vody
            </div>
            <div className={styles.itemPlusMinus}>
              <CiCirclePlus className={styles.spaceIcon} color="rgb(117, 192, 68)" size={'20px'} /> Není potřeba půda
            </div>
          </div>
          <div className="rightSide">
            <div style={{ display: 'flex', alignItems: 'center', marginTop: '15px' }}>
              <CiCircleMinus className={styles.spaceIcon} color="red" size={'20px'} /> Vyšší požadavky na vzdělání v odvěví rostlin a ryb
            </div>
            <div className={styles.itemPlusMinus}>
              <CiCircleMinus className={styles.spaceIcon} color="red" size={'20px'} /> Náročnější cesta k nalezení optima ryb, rostlin a bakterií
            </div>
          </div>
        </div>
      </Article>
      <Services />
      <ArticleText>Druhy systémů</ArticleText>
      <RawPhotoText></RawPhotoText>
    </div>
  );
};

export default Hero;
