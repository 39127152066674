import React, { useRef, useState, useEffect } from 'react';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import styles from './MyGallery.module.css'; // Import stylů, pokud existují

export default function MyGallery() {
  const galleryRef = useRef(null); // Reference na galerii
  const [isHovered, setIsHovered] = useState(false); // Stav pro hover
  const images = [
    {
      original: '/img/20240720_185116.webp',
      thumbnail: '/img/20240720_185116.webp',
    },
    {
      original: '/img/20240724_191720.webp',
      thumbnail: '/img/20240724_191720.webp',
    },
    {
      original: '/img/20240806_095358.webp',
      thumbnail: '/img/20240806_095358.webp',
    },
    {
      original: '/img/20240807_100839.webp',
      thumbnail: '/img/20240807_100839.webp',
    },
    {
      original: '/img/20241009_155445.webp',
      thumbnail: '/img/20241009_155445.webp',
    },
    {
      original: '/img/Screenshot_20240704_120531_Chrome.webp',
      thumbnail: '/img/Screenshot_20240704_120531_Chrome.webp',
    },

    {
      original: '/img/20241010_160645.webp',
      thumbnail: '/img/20241010_160645.webp',
    },
    {
      original: '/img/20241108_165711.webp',
      thumbnail: '/img/20241108_165711.webp',
    },
    {
      original: '/img/20241207_131020.webp',
      thumbnail: '/img/20241207_131020.webp',
    },
    {
      original: '/img/20241207_131028.webp',
      thumbnail: '/img/20241207_131028.webp',
    },
  ];

  useEffect(() => {
    let interval;
    if (!isHovered) {
      // Spustíme přehrávání, pokud není myš nad galerií
      interval = setInterval(() => {
        if (galleryRef.current) {
          galleryRef.current.slideToIndex((galleryRef.current.getCurrentIndex() + 1) % images.length);
        }
      }, 5000); // Interval 5 sekund
    }

    return () => clearInterval(interval); // Vyčištění při unmountu
  }, [isHovered, images.length]);

  return (
    <div
      onMouseEnter={() => setIsHovered(true)} // Myš vstoupila
      onMouseLeave={() => setIsHovered(false)} // Myš opustila
    >
      <ImageGallery
        ref={galleryRef}
        className={styles.imageGalleryImage}
        items={images}
        showNav={false}
        showFullscreenButton={false}
        showPlayButton={false}
        thumbnailPosition="bottom"
        renderItem={(item) => (
          <div className={styles.imageGallery}>
            <img src={item.original} alt="" className={styles.imageGalleryImg} />
          </div>
        )}
      />
    </div>
  );
}
